.videoLesson {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.videoLesson-header {
  /* margin-top: 10px; */
  /* margin-left: 20px; */
  font-family: 'Poppins';
  color: #714926;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: -0.5px;
  margin: 10px;
  padding: 0px;
}

.videoLesson-description {
  /* margin-top: 10px; */
  /* margin-left: 20px; */
  font-family: 'Poppins';
  color: #6f5d4e;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0px;
}

.videoLesson-body {
  width: 100%;
  height: calc(100% - 50px);
  /* Assume the header takes approximately 50px */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.videoLesson-video {
  width: 60%;
  height: 70%;
  padding: 20px;
}


.next-button {
  padding: 1em 2em;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #FF9900;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid rgba(238, 155, 31, 0.25);
  width: 120px;
  height: 40px;
  margin: 10px 20px;
}

.next-button:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #FF9900;
  box-shadow: 4px 5px 17px -4px #ae6901;
}

.next-button::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #ffba53;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.next-button:hover::before {
  width: 250%;
}

.next-button {
  font-family: 'Poppins';
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5px;
}