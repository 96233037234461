.top-question-container {
    align-items: center;
    justify-content: center;
    display: flex;
}

.central-question-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.bottom-question-container {
    align-items: center;
    justify-content: center;
    display: flex;
}

.quiz-button {
    font-family: 'Poppins';
    outline: none;
    color: #ffffff;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
    /* border: 2px dashed #DAA06D; */
    border-radius: 15px;
    background-color: #EADDCA;
    box-shadow: 0 0 0 4px #EADDCA, 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
    transition: .1s ease-in-out, .4s color;
    margin: 20px;
    width: 15vw;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    background-color: #FF9900;
    box-shadow: 0 5px 0 #924e0a;
    /* font-size: 20px; */
    /* width: 400px;
    height: 75px;
    margin: 15px; */
}


.quiz-button:hover {
    box-shadow: none;
    transform: translateY(5px);
}

.quiz-button:active {
    transform: translateX(0.1em) translateY(0.1em);
    box-shadow: 0 0 0 4px #EADDCA, 1.5px 1.5px 2.5px 1.5px rgba(0, 0, 0, 0.5);
}

.quiz-button.correct {
    background-color: #58cc02;
    box-shadow: 0 5px 0 #58a700;
}
  
.quiz-button.incorrect {
  background-color: #e10000;
  box-shadow: 0 5px 0 #a70000; 
}

.week-quiz-button {
    font-family: 'Poppins';
    outline: none;
    color: #ffffff;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
    /* border: 2px dashed #DAA06D; */
    border-radius: 15px;
    /* background-color: #EADDCA;
    box-shadow: 0 0 0 4px #EADDCA, 2px 2px 4px 2px rgba(0, 0, 0, 0.5); */
    transition: .1s ease-in-out, .4s color;
    margin: 20px;
    width: 15vw;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    background-color: #0080ff;
    box-shadow: 0 5px 0 #0a4992;
    /* font-size: 20px; */
    /* width: 400px;
    height: 75px;
    margin: 15px; */
}

.week-quiz-button:hover {
    box-shadow: none;
    transform: translateY(5px);
}

.week-quiz-button:active {
    transform: translateX(0.1em) translateY(0.1em);
    box-shadow: 0 0 0 4px #EADDCA, 1.5px 1.5px 2.5px 1.5px rgba(0, 0, 0, 0.5);
}

.week-quiz-button.correct {
    background-color: #58cc02;
    box-shadow: 0 5px 0 #58a700;
}
  
.week-quiz-button.incorrect {
  background-color: #e10000;
  box-shadow: 0 5px 0 #a70000; 
}

.module-quiz-button {
    font-family: 'Poppins';
    outline: none;
    color: #ffffff;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
    /* border: 2px dashed #DAA06D; */
    border-radius: 15px;
    /* background-color: #EADDCA;
    box-shadow: 0 0 0 4px #EADDCA, 2px 2px 4px 2px rgba(0, 0, 0, 0.5); */
    transition: .1s ease-in-out, .4s color;
    margin: 20px;
    width: 15vw;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    background-color: #e100ff;
    box-shadow: 0 5px 0 #920a8e;
    /* font-size: 20px; */
    /* width: 400px;
    height: 75px;
    margin: 15px; */
}

.module-quiz-button:hover {
    box-shadow: none;
    transform: translateY(5px);
}

.module-quiz-button:active {
    transform: translateX(0.1em) translateY(0.1em);
    box-shadow: 0 0 0 4px #EADDCA, 1.5px 1.5px 2.5px 1.5px rgba(0, 0, 0, 0.5);
}

.module-quiz-button.correct {
    background-color: #58cc02;
    box-shadow: 0 5px 0 #58a700;
}
  
.module-quiz-button.incorrect {
  background-color: #e10000;
  box-shadow: 0 5px 0 #a70000; 
}

.week-retry-button {
    font-family: 'Poppins';
    outline: none;
    color: #ffffff;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
    /* border: 2px dashed #DAA06D; */
    border-radius: 15px;
    /* background-color: #EADDCA;
    box-shadow: 0 0 0 4px #EADDCA, 2px 2px 4px 2px rgba(0, 0, 0, 0.5); */
    transition: .1s ease-in-out, .4s color;
    margin: 20px;
    width: 5vw;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    background-color: #0080ff;
    box-shadow: 0 5px 0 #0a4992;
    /* font-size: 20px; */
    /* width: 400px;
    height: 75px;
    margin: 15px; */
}

.week-quiz-button:hover {
    box-shadow: none;
    transform: translateY(5px);
}

.week-quiz-button:active {
    transform: translateX(0.1em) translateY(0.1em);
    box-shadow: 0 0 0 4px #EADDCA, 1.5px 1.5px 2.5px 1.5px rgba(0, 0, 0, 0.5);
}


.module-retry-button {
    font-family: 'Poppins';
    outline: none;
    color: #ffffff;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
    /* border: 2px dashed #DAA06D; */
    border-radius: 15px;
    /* background-color: #EADDCA;
    box-shadow: 0 0 0 4px #EADDCA, 2px 2px 4px 2px rgba(0, 0, 0, 0.5); */
    transition: .1s ease-in-out, .4s color;
    margin: 20px;
    width: 7.5vw;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    background-color: #e100ff;
    box-shadow: 0 5px 0 #920a8e;
    /* font-size: 20px; */
    /* width: 400px;
    height: 75px;
    margin: 15px; */
}

.module-retry-button:hover {
    box-shadow: none;
    transform: translateY(5px);
}

.module-retry-button:active {
    transform: translateX(0.1em) translateY(0.1em);
    box-shadow: 0 0 0 4px #EADDCA, 1.5px 1.5px 2.5px 1.5px rgba(0, 0, 0, 0.5);
}

/* Popup Container */
.popup-container {
    position: fixed;
    top: 72.5%;
    left: 35%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 17.5vw;
    z-index: 1000; /* Ensures it's above other elements */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    transform: translate(-50%, -60%); 
}

.popup-visible {
    opacity: 1;
    transform: translate(-50%, -50%); /* Final position */
}


.calc-icon {
    color: #753b09;
}

.card-button {
    background-color: white; /* Card background color */
    border: 1px solid #ddd; /* Light border for the card */
    border-radius: 8px; /* Rounded corners for the card */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    padding: 10px; /* Spacing inside the card */
    display: inline-flex; /* Center icon inside the button */
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Cursor changes to pointer to indicate it's clickable */
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow on hover */
    margin-top: 28px;
    height: 20px;
}

.card-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slightly larger shadow on hover for a "lifting" effect */
}



.hidden {
    display: none;
}

/* Calculator Style Buttons */
.mini-button {
    background-color: #0080ff;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.mini-button:hover {
    background-color: #e0e0e0;
}
