.plan {
    border-radius: 16px;
    box-shadow: 0 30px 30px -25px rgba(0, 38, 255, 0.205);
    padding: 10px;
    background-color: #fff;
    color: #697e91;
    max-width: 300px;
    margin: 20px;
  }
  
  .plan strong {
    font-weight: 600;
    color: #425275;
  }
  
  .plan .inner {
    align-items: center;
    padding: 20px;
    padding-top: 40px;
    /* background-color: #ecf0ff; */
    background-color: #afc2f6;
    border-radius: 12px;
    position: relative;
  }

  .banana {
    align-items: center;
    padding: 20px;
    padding-top: 40px;
    background-color: "#c492e6";
    color: "#B345FF";
    color-scheme: "#C492E6";
  }
  
  .plan .pricing {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #bcd5fb;
    border-radius: 99em 0 0 99em;
    display: flex;
    align-items: center;
    padding: 0.625em 0.75em;
    font-size: 1.25rem;
    font-weight: 600;
    color: #425475;
  }
  
  .plan .pricing small {
    color: #707a91;
    font-size: 0.75em;
    margin-left: 0.25em;
  }
  
  .plan .title {
    font-weight: 600;
    font-size: 1.25rem;
    color: #425675;
  }
  
  .plan .title + * {
    margin-top: 0.75rem;
  }
  
  .plan .info + * {
    margin-top: 1rem;
  }
  
  .plan .features {
    display: flex;
    flex-direction: column;
  }
  
  .plan .features li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .plan .features li + * {
    margin-top: 0.75rem;
  }
  
  .plan .features .icon {
    background-color: #1FCAC5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  
  .plan .features .icon svg {
    width: 14px;
    height: 14px;
  }
  
  .plan .features + * {
    margin-top: 1.25rem;
  }
  
  .plan .action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .plan .button {
    background-color: #6558d3;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    border: 0;
    outline: 0;
    width: 100%;
    padding: 0.625em 0.75em;
    text-decoration: none;
    cursor: pointer;
  }
  
  .plan .button:hover, .plan .button:focus {
    background-color: #4133B7;
  }

  .support-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: 80vh; */
    width: 80vw;
  }

  .support-header {
    text-align: center;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: -1.6px;
    font-weight: bold;
    padding: 10px;
    font-family: 'Josefin Sans';
  }

  .support-text {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 23px;
    font-family: 'Poppins';
    width: 60vw;
  }

  .support-card {
    background-color: #fdfaf4;
    box-shadow: black;
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0px 20px;
  }