.messaging-container {
    display: flex;
    width: 100%;
    height: 100vh;
    /* Adjust if needed */
}

.message-list {
    width: 30%;
    /* Adjust if needed */
    overflow-y: auto;
    /* For scrollable message list */
    border-right: 1px solid #ccc;
    /* A separator line */
    padding: 5px;
}

.message-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    /* Adjust if needed */
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(245, 215, 169);
    margin: 10px;

}

.message-view {
    width: 70%;
    /* Adjust if needed */
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    background-color: rgb(245, 215, 169);
    margin: 10px;

}

.message-header {
    font-family: 'Poppins';
    font-size: '26px';
    color: rgb(186, 108, 0);
}

.message-body {
    font-family: 'Poppins';
    font-size: '20px';
    color: rgb(186, 108, 0);
}

.message-delete-button {
    padding: 8px;
    outline: 0;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    background-color: rgb(211, 107, 86);
    color: #fff;
    cursor: pointer;
    margin: 10px;
}

h4 {
    margin-block-start: 0;
    margin-block-end: 0;
}