.event-history {
    width: 100%;  /* Adjust as needed */
    height: 100vh;
    padding: 10px;
    border-left: 1px solid #e0e0e0; /* Optional border between sections */
    overflow-y: auto;  /* Scroll if the content exceeds the container height */
    overflow-x: auto; 
    display: flex;
    align-items: center;  /* This keeps content centered horizontally */
    flex-direction: column;
    justify-content: flex-start;  /* This aligns content to the top vertically */
    flex: 0 0 30%; /* This means: grow=0, shrink=0, and basis=30% */
}

/* .event-card {
    padding: 10px;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
    border-radius: 5px;
} */

.event-title {
    font-family: 'Poppins';
    color: #753b09;
    font-size: 32px;
}

.event-subtitle {
    font-family: 'Poppins';
    color: #753b09;
    font-size: 18px;
}


.event-card {
    background-color: #fdfaf4;
    box-shadow: black;
    width: 20vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 10px;
  }

.your-progress-wrapper {
    display: flex;
    justify-content: space-between;
}
