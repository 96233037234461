.upload-button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #FF9900;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(238, 155, 31, 0.25);
    width: 120px;
    height: 40px;
    margin: 10px 20px;
  }

  .file-grid {
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow: scroll;
    /* height: 40vh; */
    height: auto;
}

.file-card {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 90%;
    margin: 10px
}


.file-card:hover {
    background-color: #f0f0f0;
}

.selected {
    border-color: #007bff;
    background-color: #e7f1ff;
}


  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}