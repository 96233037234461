/* progress bar */
.progress-bars-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* height: 100vh; */
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .progress-bar-card {
    background-color: #fdfaf4;
    box-shadow: black;
    width: 20vw;
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 20px;
  }

  .progress-bar-card-small {
    background-color: #fdfaf4;
    box-shadow: black;
    width: 15vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 20px;
  }

  .progress-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: 'relative';
  }

  /* .progress-button-card {
    background-color: #fbe9c6;
    box-shadow: black;
    width: 15vw;
    height: 15vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 20px;
  } */

  .progress-button-card {
    font-family: 'Poppins';
    outline: none;
    color: #ffffff;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
    /* border: 2px dashed #DAA06D; */
    border-radius: 15px;
    background-color: #EADDCA;
    box-shadow: 0 0 0 4px #EADDCA, 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
    transition: .1s ease-in-out, .4s color;
    margin: 20px;
    width: 15vw;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    background-color: #FF9900;
    box-shadow: 0 5px 0 #924e0a; 
    /* font-size: 20px; */
    /* width: 400px;
    height: 75px;
    margin: 15px; */
}

.progress-button-card:hover {
  box-shadow: none;
  transform: translateY(5px);
}

  .progress-button-card:active {
    transform: translateX(0.1em) translateY(0.1em);
    box-shadow: 0 0 0 4px #EADDCA, 1.5px 1.5px 2.5px 1.5px rgba(0, 0, 0, 0.5);
  }
  
  .progress-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 8vw;
    height: auto;
  }
  
  .progress-bar-title {
    /* margin-top: 10px; */
    margin-left: 20px;
    font-family: 'Poppins';
    color: #753b09;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -1px;
  }

  .counter-title {
    /* margin-top: 10px; */
    /* margin-left: 20px; */
    font-family: 'Poppins';
    color: #753b09;
    font-weight: bold;
    font-size: 18px;
  }

  .counter-value {
    /* margin-top: 10px; */
    /* margin-left: 20px; */
    font-family: 'Poppins';
    color: #753b09;
    font-weight: bold;
    font-size: 36px;
    letter-spacing: -1px;
  }

  .left-child {
    flex: 0 0 70%; /* This means: grow=0, shrink=0, and basis=70% */
}

  .main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  
  @keyframes progress {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }