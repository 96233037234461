.avatar-select {
    text-align: center;
  }

  .user-avatar {

  }
  
  .avatar-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px; /* Set an appropriate max-width to limit the container width */
    margin: 0 auto;
  }
  
  .avatar-item {
    width: 25%;
    cursor: pointer;
    padding: 5px;
    transition: opacity 0.2s;
    margin: 3px;
  }
  
  .avatar-item img {
    width: 100%;
    border-radius: 50%;
  }
  
  .avatar-item:hover {
    opacity: 0.7;
  }
  
  .selected {
    border: 2px solid #007bff; /* Add a border to the selected avatar */
  }
  