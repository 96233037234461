.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiContainer-root {
  border: none;
  background-color: #F8EEE2;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #b8c5e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-header {
  background-color: #F8EEE2;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.link {
  color: #02212a;
}

.link .MuiButton-root {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.social-icons {
  display: flex;
  gap: 10px;
  color: #02212a;
}

.topic-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0px;
}

.topic-content.expanded {
  max-height: 1000px; /* or any other appropriate value */
  transition: max-height 0.3s ease;
}

.navbar-title {
  color: #02212a;
  text-align: left;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -1.6px;
  font-weight: bold;
  /* padding: 5px; */
  font-family: 'Josefin Sans';
}

.main-header {
  text-align: center;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -1.6px;
  font-weight: bold;
  padding: 10px;
  color: #753b09;
  font-family: 'Poppins';
}

.sub-header {
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -1.6px;
  font-weight: bold;
  padding: 10px;
  font-family: 'Poppins';
  color: #753b09;
}

.main-header-1 {
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1.6px;
  font-weight: bold;
  padding: 10px;
  font-family: 'Poppins';
  color: #753b09;
}

.main-header-2 {
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  padding: 10px;
  font-family: 'Poppins';
  color: #753b09;
}

.main-text {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 23px;
  font-family: 'Poppins';
  color: #753b09;
}

.bold {
  font-weight: bold;
}

.main-text p,
.main-text h1,
.main-text li {
  text-align: left;
}

.description-text {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 15px;
}

.price {
  text-align: center;
  font-weight: bold;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 20px;
}

.testimonial {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
}

.centered-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-register-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 10px;
  border-radius: 10px;
}

.register-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #52a3fa;
  color: #ffffff;
  border-radius: 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  border: none;
  outline: none;
}

.register-button:hover {
  background-color: #8cb6fa;
}

.register-button:focus {
  outline: none;
  /* box-shadow: 0 0 0 3px rgba(60, 135, 239, 0.5); */
}

/* This is css to style the user avatar at the top of the dashboard */
.user-card {
  display: flex;
  align-items: center;
  margin: 20px;
  cursor: pointer;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-name {
  margin-left: 10px;
  color: #80520E;
}


/* .progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-inner {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.progress-container {
  position: flex;
  display: flex;
  justify-content: center;
}

.progress-loader {
  position: flex;
  display: flex;
  align-items: center;
  width: 400px;
  background: rgb(136, 136, 158);
  height: 10px;
  border-radius: 7px;
  border: 1px solid black;
  margin: 20px;
}

.progress {
  width: 1px;
  height: 8px;
  border-radius: 7px;
  background: rgb(220, 38, 38);
  transition: width 0.3s ease;
} */

@keyframes loading_44 {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.edit-button {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: inherit;
  color: inherit;
  opacity: 0.5;
  cursor: pointer;
  text-decoration: bold;
}

.side-nav {
  /* flex: 0 0 auto; */
  width: 200px;
  background-color: #f1f1f1;
  padding: 20px;
  /* display: flex; */
  /* justify-content: center;
  align-items: center;
  height: auto; */
}

.nav-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ddd;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  text-align: left;
  color: #333;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #E67E22;
}


.parent-nav-button {
  padding: 1em 2em;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #2c9caf;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid #2c9caf;
}

.parent-nav-button:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #70bdca;
  box-shadow: 4px 5px 17px -4px #268391;
}

.parent-nav-button::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #2c9caf;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.parent-nav-button:hover::before {
  width: 250%;
}

.central-box {
  flex: 1;
  background-color: #F8EEE2;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CircularProgressbar-text {
  text-anchor: middle;
  alignment-baseline: middle;
}


/* html, */
body {
  margin: 0;
  /* --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  height: 100%;
  margin: 0;
  --background: var(--purple);
  --accent: var(--white);

  background-color: var(--background);
  color: var(--accent); */
}

.content {
  flex: 1;
  display: flex;
}

/* Used for all main page content */
.fill-screen {
  display: flex;
  min-height: 100vh;
}

/* .fill-screen-h {
  display: flex;
  min-width: 100vw;
  align-items: center;
  /* justify-content: center; */
/* } */

.fill-screen-no-flex {
  min-height: 100vh;
}

.footer {
  background-color: #f1f1f1;
  padding: 20px;
  margin-top: auto;
  text-align: center;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  width: 200px;
  text-align: right;
  margin-right: 10px;
}

.form-group input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/*
Quiz CSS
*/

.options-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.options-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

button {
  all: unset;
  display: inline-block;
  padding: 20px;
  color: white;
  /* text-transform: uppercase; */
  font-family: sans-serif;
  letter-spacing: 1.5px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 17px;
}

.option-button {
  text-align: center;
  font-size: 20px;
  background-color: #007bff;
  box-shadow: 0 5px 0 #0068d6; 
  width: 400px;
  height: 75px;
  margin: 15px;
}

.option-button:active {
  box-shadow: none;
  transform: translateY(5px);
}

.option-button.selected {
  background-color: #8f3ca0;
}

.option-button.correct {
  background-color: #58cc02;
  box-shadow: 0 5px 0 #58a700; 
}

.option-button.incorrect {
  background-color: #e10000;
  box-shadow: 0 5px 0 #a70000; 
}

/* .option-button:hover {
  background-color: #3490f1;
} */

.mini-button {
  text-align: center;
  background-color: #007bff;
  box-shadow: 0 5px 0 #0068d6; 
  width: 20px;
  height: 20px;
  margin: 15px;
  font-family: 'Poppins';
}

.submit-button {
  text-align: center;
  background-color: #007bff;
  box-shadow: 0 5px 0 #0068d6; 
  width: 200px;
  margin: 15px;
}

.submit-button:hover {
  background-color: #3490f1;
}

.submit-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  /* hacky way to remove background */
  background-color: rgba(0,0,255,0);
}

.quiz-progress-box {
  width: 20px;
  height: 20px;
  border: 1px solid gray;
  border-radius: 50%;
  margin: 0 5px;
  background-color: grey;
  transition: background-color 2s ease;
}

.quiz-progress-box.correct {
  background-color: green;
  transition: background-color 2s ease;
}

.quiz-progress-box.current {
  background-color: grey;
  transition: background-color 2s ease;
}

.quiz-progress-box.incorrect {
  background-color: red;
  transition: background-color 2s ease;
}

.open-input {
  padding: 10px;
  font-size: 24px;
  border-radius: 5px;
  border: 1px solid gray;
  width: 300px;
  font-weight: bold;
}

.result-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-top: 20px;
}

.result-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.score {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.percentage {
  font-size: 18px;
  margin-bottom: 20px;
}

.result-emoji {
  font-size: 64px;
  margin-bottom: 20px;
}

.box {
  width: 50px;
  height: 50px;
  background: darkblue;
  padding: 30px;
}

.scrollable-container {
  height: 100vh;  /* Take the full viewport height */
  overflow-y: auto;  /* Add scroll if content is taller than the container */
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lesson-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lesson-display > * {
  margin: 20px;
  padding: 20px;
}

.video-wrapper {
  position: relative;
  display: inline-block;
  border: none;
  outline: none;
  margin: 0;
  
}

.video-wrapper video {
  display: block;
  width: 100%; /* or any width you desire */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7); /* This gives a white semi-transparent overlay */
  pointer-events: none; /* To ensure video controls are accessible */
  z-index: 1;
  backdrop-filter: grayscale(100%); /* Will make the video underneath greyed out */
}

.no-access .overlay::before {
  content: 'Subscribe to access videos.';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-weight: bold;
  z-index: 2;
  pointer-events: none;
}

.table-container {
  display: block;
  max-height: 80vh; /* Adjust as needed */
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f5f5; /* Light grey background for the whole table container */
}

.card-row {
  background-color: #f2e8df; /* Cream background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* subtle shadow */
  margin: 10px 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* smooth transition for hover effect */
  cursor: pointer;
}

.card-row:hover {
  transform: translateY(-2px); /* slight raise effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* stronger shadow on hover */
}

.card-row td {
  padding: 10px; /* Add padding inside the cells to give a card-like feel */
  border-bottom: 1px solid #eaeaea;
}

thead tr th {
  padding: 10px;
}