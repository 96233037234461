
.subject-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #52a3fa;
    color: #ffffff;
    border-radius: 10px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
    border: none;
    outline: none;
  }
  
  .subject-button:hover {
    background-color: #8cb6fa;
  }
  
  .subject-button:focus {
    outline: none;
    /* box-shadow: 0 0 0 3px rgba(60, 135, 239, 0.5); */
  }


  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    border-radius: 10px;
    color: 

}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
