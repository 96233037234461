.pay-button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #09941c;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(86, 220, 65, 0.25);
    width: 120px;
    height: 40px;
    margin: 20px 20px;
}

.pay-button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #005d13;
    box-shadow: 4px 5px 17px -4px #005325;
}

.pay-button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #4edd15;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}

.pay-button:hover::before {
    width: 250%;
}

.x-pay-button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #09941c;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(220, 70, 65, 0.25);
    width: 120px;
    height: 40px;
    margin: 20px 20px;
}

.x-pay-button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #005d13;
    box-shadow: 4px 5px 17px -4px #005325;
}

.x-pay-button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #4edd15;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}

.x-pay-button:hover::before {
    width: 250%;
}

.payment-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 15px;
}

.payment-box > .back-button {
    align-self: flex-start;
}

.sub-changes {
    width: auto;
    padding: 25px;
}

.collapsible-content {
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    max-height: 0;
}

.collapsible-content.expanded {
    max-height: 500px; /* You may need to adjust this based on your actual content height */
}

.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0px;
    /* margin: 8px 0; */
    font-size: 1rem;
    color: grey;
}
