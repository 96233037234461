.parent-portal {
    background-color: #fdfaf4;
    /* color: rgb(238, 156, 4); */
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    height: 100vh;
  }
  
  .portal h1 {
    font-family: 'Josefin Sans';
    font-weight: bold;
    font-size: 40px;
  }
  
  .parent-portal button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #FF9900;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(238, 155, 31, 0.25);
    width: 120px;
    height: 40px;
    margin: 10px 20px;
  }
  
  .parent-portal button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #FF9900;
    box-shadow: 4px 5px 17px -4px #ae6901;
  }
  
  .parent-portal button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ffba53;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }
  
  .parent-portal button:hover::before {
    width: 250%;
  }
  
  .parent-portal p,
  .portal li,
  .portal button,
  .portal label,
  .portal a {
    font-family: 'Poppins';
    font-size: 16px;
  }
  
  .portal p,
  .portal li,
  .portal h1,
  .portal button {
    text-align: center;
  }
  
  .curved {
    border-radius: 15px;
  }
  
  
  .portal textarea {
    border-radius: 8px;
    height: 100px;
    width: 100%;
    resize: none;
    outline: 0;
    padding: 8px 14px;
    margin: 25px;
    border: 1px solid rgb(219, 213, 213);
    font-family: 'Poppins';
  }