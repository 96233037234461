.la-header {
    margin-top: 20px;
    font-family: 'Poppins';
    font-size: 36px;
    letter-spacing: -1px;
}

.la-subheader {
    margin-top: 20px;
    font-family: 'Poppins';
    font-size: 28px;
    letter-spacing: -0.6px;
}

.la-para {
    font-family: 'Poppins';
    font-size: 22px;
}

.la-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.la-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-top: 20px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.la-account-card {
    background-color: rgb(192, 187, 183);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.la-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.copied-popup {
    position: 'absolute';
    top: '100%';
    left: '50%';
    transform: 'translateX(-50%)';
    background: 'rgba(0, 0, 0, 0.7)';
    color: 'green';
    padding: '5px 10px';
    border-radius: '5px';
    margin-top: '5px';
}

.copy-icon {
    margin: 7.5px;
}

.la-button {
    font-family: 'Poppins';
    outline: none;
    color: #ffffff;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
    border-radius: 15px;
    background-color: #EADDCA;
    box-shadow: 0 0 0 4px #EADDCA, 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
    transition: .1s ease-in-out, .4s color;
    margin: 10px;
    margin-bottom: 20px;
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    background-color: #525af4;
    box-shadow: 0 5px 0 #0a0e92;
    /* font-size: 20px; */
    /* width: 400px;
    height: 75px;
    margin: 15px; */
}

.la-button:hover {
    box-shadow: none;
    transform: translateY(5px);
}

.la-button:active {
    transform: translateX(0.1em) translateY(0.1em);
    box-shadow: 0 0 0 4px #EADDCA, 1.5px 1.5px 2.5px 1.5px rgba(0, 0, 0, 0.5);
}

.la-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 15px;
    padding: 10px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.la-input:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(0,123,255,0.5);
}