.payment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fdfaf4;
    box-shadow: black;
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 15px;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1); */
    box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1);
}

.account-container {
    background-color: #ffe5ac;
    box-shadow: black;
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    border-radius: 15px;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1); */
    box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 25px;
    padding-left: 30px;
    padding-right: 30px;
    /* TODO add smooth width transitions here */
    width: auto;
}

.payment button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FF9900;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(255, 153, 0, 0.25);
    width: 120px;
    height: 40px;
    margin: 10px 20px;
}

.subject-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.payment div {
    padding: 10px;
}

.horizontal-align {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
}

.sub-button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #09941c;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(5, 130, 16, 0.25);
    width: 120px;
    height: 40px;
    margin: 20px 20px;
}

.sub-button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #005d13;
    box-shadow: 4px 5px 17px -4px #005325;
}

.sub-button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #4edd15;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}

.sub-button:hover::before {
    width: 250%;
}

.unsub-button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #f47171;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(220, 70, 65, 0.25);
    width: 120px;
    height: 40px;
    margin: 20px 20px;
}

.unsub-button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #ff0000;
    box-shadow: 4px 5px 17px -4px #ae1201;
}

.unsub-button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ff6868;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}

.unsub-button:hover::before {
    width: 250%;
}

.mini-unsub-button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #f47171;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(220, 70, 65, 0.25);
    width: auto;
    height: auto;
    margin: 20px 20px;
}

.mini-unsub-button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #ff0000;
    box-shadow: 4px 5px 17px -4px #ae1201;
}

.mini-unsub-button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ff6868;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}

.mini-unsub-button:hover::before {
    width: 250%;
}

/* Entering (fading in) */
.fade-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
}

.fade-enter-active {
    opacity: 1;
    max-height: 500px;
    transition: opacity 300ms, max-height 300ms;
}

/* Exiting (fading out) */
.fade-exit {
    opacity: 1;
    max-height: 500px;
}

.fade-exit-active {
    opacity: 0;
    max-height: 0;
    transition: opacity 300ms, max-height 300ms;
}

/* Styling the table */
table {
    border-collapse: separate;
    border-spacing: 10px 10px; /* 0 horizontal spacing, 10px vertical spacing */
}

/* Styling the rows */
table tbody tr {
    /* background-color: #FFFFFF; assuming you want a white background, adjust as needed */
}

/* Styling the cells */
table tbody td {
    padding: 5px 10px; /* adjust padding as needed */
}