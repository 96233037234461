.configure-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw;
    flex-direction: column;
    text-align: center;
}

.configure-header {
    margin-top: 20px;
    font-family: 'Poppins';
    font-size: 36px;
}

.avatar-header {
    margin-top: 20px;
    font-family: 'Poppins';
    font-size: 30px;
}

.confirm-button {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #FF9900;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid rgba(238, 155, 31, 0.25);
    width: 120px;
    height: 40px;
    margin: 20px 20px;
  }
  
  .confirm-button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #FF9900;
    box-shadow: 4px 5px 17px -4px #ae6901;
  }
  
  .confirm-button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ffba53;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }
  
  .confirm-button:hover::before {
    width: 250%;
  }

/* Parent/student selector CSS */
.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
}

.checkbox-wrapper-35 .switch {
    display: none;
  }
  
  .checkbox-wrapper-35 .switch + label {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #78768d;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 15px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .checkbox-wrapper-35 .switch + label::before,
    .checkbox-wrapper-35 .switch + label::after {
    content: '';
    display: block;
  }
  
  .checkbox-wrapper-35 .switch + label::before {
    background-color: #05012c;
    border-radius: 500px;
    height: 30px;
    margin-right: 8px;
    -webkit-transition: background-color 0.125s ease-out;
    transition: background-color 0.125s ease-out;
    width: 50px;
  }
  
  .checkbox-wrapper-35 .switch + label::after {
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 3px 1px 0 rgba(37, 34, 71, 0.05), 0 2px 2px 0 rgba(37, 34, 71, 0.1), 0 3px 3px 0 rgba(37, 34, 71, 0.05);
    height: 26px;
    left: 2px;
    position: absolute;
    top: 2px;
    -webkit-transition: -webkit-transform 0.125s ease-out;
    transition: -webkit-transform 0.125s ease-out;
    transition: transform 0.125s ease-out;
    transition: transform 0.125s ease-out, -webkit-transform 0.125s ease-out;
    width: 26px;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-text {
    display: block;
    margin-right: .3em;
    font-size: 14px;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-toggletext {
    display: block;
    font-weight: bold;
    height: 15px;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    width: 60px;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-unchecked,
    .checkbox-wrapper-35 .switch + label .switch-x-checked {
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 0.125s ease-out, -webkit-transform 0.125s ease-out;
    transition: opacity 0.125s ease-out, -webkit-transform 0.125s ease-out;
    transition: transform 0.125s ease-out, opacity 0.125s ease-out;
    transition: transform 0.125s ease-out, opacity 0.125s ease-out, -webkit-transform 0.125s ease-out;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-unchecked {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-checked {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-hiddenlabel {
    position: absolute;
    visibility: hidden;
  }
  
  .checkbox-wrapper-35 .switch:checked + label::before {
    background-color: #ffb500;
  }
  
  .checkbox-wrapper-35 .switch:checked + label::after {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  
  .checkbox-wrapper-35 .switch:checked + label .switch-x-unchecked {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  
  .checkbox-wrapper-35 .switch:checked + label .switch-x-checked {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  
  /* Input box CSS */
  .wave-group {
    position: relative;
    margin-top: 25px;
  }
  
  .wave-group .input {
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 200px;
    border: none;
    border-bottom: 1px solid #515151;
    background: transparent;
  }
  
  .wave-group .input:focus {
    outline: none;
  }
  
  .wave-group .label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    display: flex;
  }
  
  .wave-group .label-char {
    transition: 0.2s ease all;
    transition-delay: calc(var(--index) * .05s);
  }
  
  .wave-group .input:focus ~ label .label-char,
  .wave-group .input:valid ~ label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: #5264AE;
  }
  
  .wave-group .bar {
    position: relative;
    display: block;
    width: 200px;
  }
  
  .wave-group .bar:before,.wave-group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #5264AE;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  
  .wave-group .bar:before {
    left: 50%;
  }
  
  .wave-group .bar:after {
    right: 50%;
  }
  
  .wave-group .input:focus ~ .bar:before,
  .wave-group .input:focus ~ .bar:after {
    width: 50%;
  }
  
  .link-request-notification {
    margin-bottom: 20px;
  }
  
  .la-input {
    width: 50%;
  }