
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 50px 0; /* Adds space above and below the loading symbol */
  }
  
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(255, 255, 255);
    border-color: rgb(121, 139, 255) transparent rgb(121, 139, 255) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    width: 100vw;
    margin-bottom: 30px;
  }

  .flex-column {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
  }

  .help-message {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #f2f4f8;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    color: #333;
    font-size: 0.9rem;
    text-align: center;
    width: 80%;
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

  
