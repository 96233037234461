.le-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.le-header {
  /* margin-top: 10px; */
  /* margin-left: 20px; */
  margin: 10px;
  font-family: 'Poppins';
  color: #753b09;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: -1px;
}

.le-subheader {
  /* margin-top: 10px; */
  /* margin-left: 20px; */
  margin: 10px;
  font-family: 'Poppins';
  color: #753b09;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: -0.5px;
}