.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
    width: 80vw;
  }


  .form {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 60vw;
    height: 50vh;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 30px 30px -30px rgba(27, 26, 26, 0.315);
  }
  
  .form .title {
    color: royalblue;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-family: 'Poppins';
  }
  
  .form input {
    outline: 0;
    border: 1px solid rgb(219, 213, 213);
    padding: 8px 14px;
    border-radius: 8px;
    width: 100%;
    height: 50px;
  }

  .form p {
    color: black;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins';
    line-height: 20px;
    padding: 10px;
  }
  
  .form textarea {
    border-radius: 8px;
    height: 100px;
    width: 100%;
    resize: none;
    outline: 0;
    padding: 8px 14px;
    border: 1px solid rgb(219, 213, 213);
  }
  
  .form button {
    align-self: flex-end;
    padding: 8px;
    outline: 0;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    background-color: royalblue;
    color: #fff;
    cursor: pointer;
  }
      

  .popup-notification {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 24px;
    background-color: #4CAF50; /* You can choose your own color */
    color: white;
    border-radius: 4px;
    z-index: 1000;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    margin: 5px;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.popup-notification.show {
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
}

.error-notification {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  background-color: #ec1010; /* You can choose your own color */
  color: white;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.error-notification.show {
opacity: 1;
transition: opacity 0.3s ease-in-out;
}