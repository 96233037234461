.subject-container {
    /* background-color: #fdfaf4; */
    background-color: #dfddf9;
    box-shadow: black;
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 15px;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1); */
    box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1);
    margin: 20px 20px;
}

.subject-sub-container {
    display: flex;
    flex-direction: row;
}

.subject-edit {
    color: #be7f48;
}

.module-edit {
    color: #be7f48;
}

.week-edit {
    color: #be7f48;
}

.lesson-edit {
    color: #be7f48;
}

.module-container {
    /* background-color: #cedff8; */
    background-color: #faf1de;
    box-shadow: black;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    /* box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1); */
    margin: 10px 30px 0px 0px;
}

.week-container {
    /* background-color: #b3cff9; */
    background-color: #ebddf9;
    box-shadow: black;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    /* box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1); */
    margin: 10px;
}

.standalone-week-container {
    /* background-color: #b3cff9; */
    background-color: #ebddf9;
    box-shadow: black;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    /* box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1); */
    margin: 10px;
}

.lesson-container {
    /* background-color: #84b2f7; */
    background-color: #f8e5bc;
    box-shadow: black;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
    /* box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1); */
    margin: 10px;
}

.module-button {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: bold;
    border-radius: 10px;
}

.subject-input {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: bold;
    border-radius: 10px;
}

.module-input {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: bold;
    border-radius: 10px;
}

.week-input {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: bold;
    border-radius: 10px;
}

.lesson-input {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: bold;
    border-radius: 10px;
}

.subject-header {
    text-align: left;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: -1.6px;
    font-weight: bold;
    padding: 10px;
    font-family: 'Poppins';
    color: #753b09;
    margin: 20px;
}

.subject-description {
    text-align: left;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0px;
    padding: 10px;
    font-family: 'Poppins';
    color: #753b09;
    margin: 0px 30px 10px 30px;
}

.module-header {
    text-align: left;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -1.4px;
    font-weight: bold;
    /* padding: 10px; */
    font-family: 'Poppins';
    color: #753b09;
    margin: 5px;
}

.module-description {
    text-align: left;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0px;
    padding: 10px;
    font-family: 'Poppins';
    color: #753b09;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0px;
    /* height: 0; */
    /* margin: 0px 30px 10px 10px; */
    margin: 0px 30px 20px 30px;
}

.module-description.expanded {
    max-height: 1000px;
    /* or any other appropriate value */
    transition: max-height 0.3s ease;
}

.week-header {
    text-align: left;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -1px;
    font-weight: bold;
    /* padding: 10px; */
    font-family: 'Poppins';
    color: #753b09;
    color: inherit;
    margin: 5px;
}

.week-description {
    text-align: left;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0px;
    padding: 10px;
    font-family: 'Poppins';
    color: #753b09;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0px;
    margin: 0px 30px 10px 30px;
}

.week-description.expanded {
    max-height: 1000px;
    /* or any other appropriate value */
    transition: max-height 0.3s ease;
}

.lesson-header {
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    font-weight: bold;
    /* padding: 10px; */
    font-family: 'Poppins';
color: #753b09;
    color: inherit;
    margin: 5px;
}

.lesson-description {
    text-align: left;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0px;
    padding: 10px;
    font-family: 'Poppins';
    color: #753b09;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0px;
    margin: 0px 30px 10px 30px;
    /* height: 0; */
}

.lesson-description.expanded {
    max-height: 1000px;
    /* or any other appropriate value */
    transition: max-height 0.3s ease;
}

.add-subject {
    color: #753b09;
    font-family: 'Poppins';
    border-radius: 15px;
    box-shadow: black;
    box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1);
    margin: 30px;
    width: 74vw;
    background-color: rgb(178, 232, 232);
}

.lesson-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lesson-display > * {
    margin: 20px;
    padding: 20px;
}